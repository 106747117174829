/* eslint-disable @typescript-eslint/no-var-requires */
const {
    homeDogBoarding,
    homeDogBoardingService,
    dogWalking,
    dogWalkingService,
    doggyDayCare,
    doggyDayCareService,
    houseSitting,
    houseSittingService,
    homeVisitsService,
} = require('./index');

const oneHomeVisit = 'oneHomeVisit';
const twoHomeVisits = 'twoHomeVisits';

const oneHomeVisitService = {
    id: oneHomeVisit,
    visible: true,
    synonyms: [],
};

const twoHomeVisitsService = {
    id: twoHomeVisits,
    visible: true,
    synonyms: [],
};

const directBookingDrupalVariant = {
    petBoarding: 1,
    doggyDayCare: 7,
    oneHomeVisit: 2,
    twoHomeVisits: 3,
    dogWalking: 6,
    sleepover: 4,
};

const servicesWithDrupalMapping = {
    1: homeDogBoardingService,
    2: oneHomeVisitService,
    3: twoHomeVisitsService,
    4: houseSittingService,
    6: dogWalkingService,
    7: doggyDayCareService,
};

const getServiceBasedUponDrupalId = (drupalId) => {
    return servicesWithDrupalMapping[drupalId];
};

const getDrupalVariantForBookableService = (id) => {
    return directBookingDrupalVariant[id];
};

const getBookableServiceBasedUponId = (id) => {
    switch (id) {
        case homeDogBoarding:
            return homeDogBoardingService;
        case doggyDayCare:
            return doggyDayCareService;
        case dogWalking:
            return dogWalkingService;
        case oneHomeVisit:
            return oneHomeVisitService;
        case twoHomeVisits:
            return twoHomeVisitsService;
        case houseSitting:
            return houseSittingService;
    }
};

const getSeoServiceBasedUponBookableServiceId = (id) => {
    switch (id) {
        case homeDogBoarding:
            return homeDogBoardingService;
        case doggyDayCare:
            return doggyDayCareService;
        case dogWalking:
            return dogWalkingService;
        case oneHomeVisit:
        case twoHomeVisits:
            return homeVisitsService;
        case houseSitting:
            return houseSittingService;
    }
};

module.exports = {
    getBookableServiceBasedUponId,
    oneHomeVisit,
    twoHomeVisits,
    oneHomeVisitService,
    twoHomeVisitsService,
    getDrupalVariantForBookableService,
    getServiceBasedUponDrupalId,
    getSeoServiceBasedUponBookableServiceId,
};
