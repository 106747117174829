/* eslint-disable @typescript-eslint/no-var-requires */
const { oneHomeVisit, twoHomeVisits } = require('../../../Pawshake/Core/Service/BookableService');

const {
    catSitting,
    doggyDayCare,
    dogSitting,
    dogWalking,
    homeDogBoarding,
    houseSitting,
    petSitting,
    getServiceBasedUponId,
    homeVisits,
} = require('../../../Pawshake/Core/Service');

const memoize = require('lodash/memoize');

const {
    GermanAustria,
    GermanBelgium,
    GermanSwitzerland,
    GermanGermany,
    GermanLuxembourg,
    EnglishAustralia,
    EnglishBelgium,
    EnglishCanada,
    EnglishGermany,
    EnglishFrance,
    EnglishGreatBritain,
    EnglishHongKong,
    EnglishIreland,
    EnglishLuxembourg,
    EnglishNetherlands,
    EnglishNewZealand,
    EnglishSwitzerland,
    EnglishSingapore,
    EnglishJapan,
    FrenchBelgium,
    FrenchCanada,
    FrenchFrance,
    FrenchLuxembourg,
    FrenchSwitzerland,
    ItalianItaly,
    ItalianSwitzerland,
    DutchBelgium,
    DutchNetherlands,
    DanishDenmark,
    FinnishFinland,
    NorwegianNorway,
    SwedishSweden,
} = require('../../../Pawshake/Core/Locale/Locale');

const GermanServiceSlugs = {
    [homeDogBoarding]: 'hundebetreuung',
    [doggyDayCare]: 'hundetagesbetreuung',
    [dogWalking]: 'gassi-gehen',
    [houseSitting]: 'haussitter',
    [petSitting]: 'tiersitter',
    [dogSitting]: 'hundesitter',
    [catSitting]: 'katzenbetreuung',
};

const EnglishServiceSlugs = {
    [homeDogBoarding]: 'home-dog-boarding',
    [doggyDayCare]: 'doggy-day-care',
    [dogWalking]: 'dog-walking',
    [houseSitting]: 'house-sitting',
    [petSitting]: 'petsitters',
    [dogSitting]: 'dog-sitting',
    [catSitting]: 'cat-sitting',
};

const FrenchServiceSlugs = {
    [homeDogBoarding]: 'hebergement-pour-chiens',
    [doggyDayCare]: 'garde-chien',
    [dogWalking]: 'promenade-chien',
    [houseSitting]: 'gardiennage-maison',
    [petSitting]: 'garde-animaux',
    [dogSitting]: 'dog-sitter',
    [catSitting]: 'garde-chat',
};

const ItalianServiceSlugs = {
    [homeDogBoarding]: 'pensione-per-cani',
    [doggyDayCare]: 'asilo-per-cani',
    [dogWalking]: 'passeggiate-cani',
    [houseSitting]: 'house-sitting',
    [petSitting]: 'pet-sitter',
    [dogSitting]: 'dog-sitter',
    [catSitting]: 'cat-sitter',
};

const DutchServiceSlugs = {
    [homeDogBoarding]: 'hondenoppas',
    [doggyDayCare]: 'hondenopvang',
    [dogWalking]: 'hondenuitlaatservice',
    [houseSitting]: 'huisoppas',
    [petSitting]: 'dierenoppas',
    [dogSitting]: 'dogsitter',
    [catSitting]: 'kattenoppas',
};

const ServiceSlugsPerLocale = {
    [GermanAustria]: GermanServiceSlugs,
    [GermanBelgium]: GermanServiceSlugs,
    [GermanSwitzerland]: GermanServiceSlugs,
    [GermanGermany]: GermanServiceSlugs,
    [GermanLuxembourg]: GermanServiceSlugs,
    [EnglishAustralia]: EnglishServiceSlugs,
    [EnglishBelgium]: EnglishServiceSlugs,
    [EnglishCanada]: EnglishServiceSlugs,
    [EnglishGermany]: EnglishServiceSlugs,
    [EnglishFrance]: EnglishServiceSlugs,
    [EnglishGreatBritain]: EnglishServiceSlugs,
    [EnglishHongKong]: EnglishServiceSlugs,
    [EnglishIreland]: EnglishServiceSlugs,
    [EnglishLuxembourg]: EnglishServiceSlugs,
    [EnglishNetherlands]: EnglishServiceSlugs,
    [EnglishNewZealand]: EnglishServiceSlugs,
    [EnglishSwitzerland]: EnglishServiceSlugs,
    [EnglishSingapore]: EnglishServiceSlugs,
    [EnglishJapan]: EnglishServiceSlugs,
    [FrenchBelgium]: FrenchServiceSlugs,
    [FrenchCanada]: FrenchServiceSlugs,
    [FrenchSwitzerland]: FrenchServiceSlugs,
    [FrenchFrance]: FrenchServiceSlugs,
    [FrenchLuxembourg]: FrenchServiceSlugs,
    [ItalianItaly]: ItalianServiceSlugs,
    [ItalianSwitzerland]: ItalianServiceSlugs,
    [DutchBelgium]: DutchServiceSlugs,
    [DutchNetherlands]: DutchServiceSlugs,
    [DanishDenmark]: {
        [homeDogBoarding]: 'hundepasning',
        [doggyDayCare]: 'hundebornehave',
        [dogWalking]: 'hundeluftning',
        [houseSitting]: 'house-sitting',
        [petSitting]: 'dyrepassere',
        [catSitting]: 'kattepasning',
    },
    [FinnishFinland]: {
        [homeDogBoarding]: 'koiran-taysihoitoa',
        [doggyDayCare]: 'koiran-paivahoito',
        [dogWalking]: 'koiran-ulkoilutus',
        [houseSitting]: 'talonvahtipalvelu',
        [petSitting]: 'elaintenhoitaja',
        [dogSitting]: 'koiran-hoito',
        [catSitting]: 'kissan-hoito',
    },
    [NorwegianNorway]: {
        [homeDogBoarding]: 'hundepass',
        [doggyDayCare]: 'hundebarnehage',
        [dogWalking]: 'hundelufting',
        [houseSitting]: 'house-sitting',
        [petSitting]: 'dyrepass',
        [catSitting]: 'kattepass',
    },
    [SwedishSweden]: {
        [homeDogBoarding]: 'hundvakt',
        [doggyDayCare]: 'hunddagis',
        [dogWalking]: 'hundrastning',
        [houseSitting]: 'husvakt',
        [petSitting]: 'djurpassning',
        [catSitting]: 'kattvakt',
    },
};

const getServiceSlugInLocale = (service, locale) => {
    let serviceId = service.id;

    if (service.id === oneHomeVisit || service.id === twoHomeVisits || service.id === homeVisits) {
        serviceId = petSitting;
    }

    const localeString = locale.language + '-' + locale.country;

    if (ServiceSlugsPerLocale[localeString] && ServiceSlugsPerLocale[localeString][serviceId]) {
        return ServiceSlugsPerLocale[localeString][serviceId];
    }

    return EnglishServiceSlugs[serviceId];
};

const generateSlugServiceMapping = () => {
    let slugs = {};

    Object.keys(ServiceSlugsPerLocale).forEach((locale) => {
        Object.keys(ServiceSlugsPerLocale[locale]).forEach((service) => {
            slugs[ServiceSlugsPerLocale[locale][service]] = service;
        });
    });

    return slugs;
};

const generateSitterServiceSlugs = () => {
    let slugs = {};

    Object.keys(ServiceSlugsPerLocale).forEach((locale) => {
        Object.keys(ServiceSlugsPerLocale[locale]).forEach((service) => {
            if (service === petSitting) {
                slugs[ServiceSlugsPerLocale[locale][service]] = service;
            }
        });
    });

    return slugs;
};

const getServiceSlugsMapping = memoize(generateSlugServiceMapping);

const getServiceSlugs = () => {
    return Object.keys(getServiceSlugsMapping());
};

const getSitterServiceSlugs = () => {
    return Object.keys(generateSitterServiceSlugs());
};

const getServiceBasedUponServiceSlug = (serviceSlug) => {
    const serviceId = getServiceSlugsMapping()[serviceSlug];
    return getServiceBasedUponId(serviceId);
};

module.exports = {
    getServiceSlugInLocale,
    getServiceSlugs,
    getSitterServiceSlugs,
    getServiceBasedUponServiceSlug,
};
